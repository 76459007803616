import { keyBy, unionBy } from "lodash";
import { handleActions, handleAction } from "redux-actions";
import { DEFAULT_TASKS_COLUMNS } from "../../constants/tasks";
import {
  ADD_NEW_ALERTS,
  ALERTS_FORM_TOOGLE_LOADING,
  CLOSE_ALERTS_FORM,
  DELETE_ALERT,
  EDIT_SELECTED_ALERT,
  OPEN_ALERTS_FORM,
  SET_ALERTS,
  UPDATE_ALERTS,
} from "../../plugins/Alert/constants";
import { defaultDateFilter } from "../../plugins/Checklist/constants";
import {
  ADD_NEW_CLIENTS,
  ADD_NEW_PRODUCTS,
  ADD_NEW_PROVIDERS,
  ADD_NEW_RESOURCES,
  ADD_NEW_UNITS,
  ADD_NEW_WORKORDERS,
  CLIENTS_FORM_TOOGLE_LOADING,
  CLOSE_CLIENTS_FORM,
  CLOSE_PRODUCTS_FORM,
  CLOSE_PROVIDERS_FORM,
  CLOSE_RESOURCES_FORM,
  CLOSE_UNITS_FORM,
  CLOSE_WORKORDERS_FORM,
  DELETE_CLIENT,
  DELETE_PRODUCT,
  DELETE_PROVIDER,
  DELETE_RESOURCE,
  DELETE_UNIT,
  DELETE_WORKORDER,
  EDIT_SELECTED_CLIENT,
  EDIT_SELECTED_PRODUCT,
  EDIT_SELECTED_PROVIDER,
  EDIT_SELECTED_RESOURCE,
  EDIT_SELECTED_UNIT,
  EDIT_SELECTED_WORKORDER,
  OPEN_CLIENTS_FORM,
  OPEN_PRODUCTS_FORM,
  OPEN_PROVIDERS_FORM,
  OPEN_RESOURCES_FORM,
  OPEN_UNITS_FORM,
  OPEN_WORKORDERS_FORM,
  PRODUCTS_FORM_TOOGLE_LOADING,
  PROVIDERS_FORM_TOOGLE_LOADING,
  RESOURCES_FORM_TOOGLE_LOADING,
  SET_CLIENTS,
  SET_PRODUCTS,
  SET_PROVIDERS,
  SET_RESOURCES,
  SET_UNITS,
  SET_WORKORDERS,
  UNITS_FORM_TOOGLE_LOADING,
  UPDATE_CLIENTS,
  UPDATE_PRODUCTS,
  UPDATE_PROVIDERS,
  UPDATE_RESOURCES,
  UPDATE_UNITS,
  UPDATE_WORKORDERS,
  WORKORDERS_FORM_TOOGLE_LOADING,
} from "../../plugins/SAT/constants";
import {
  AUTH,
  SET_PROFILE,
  SET_SIDE_MENU_STATE,
  SET_STATUS_SETTINGS,
  SET_CURRENT_ROUTE,
  OPEN_PROJECTS_FORM,
  CLOSE_TASK_FORM,
  EDIT_SELECTED_TASK,
  SET_CATEGORIES,
  SET_SERVICES,
  SET_ALL_TASKS,
  LOGOUT,
  ADD_NEW_TASK,
  UPDATE_TASK,
  SHOW_NOTIFICATION,
  CLOSE_NOTIFICATION,
  SHOW_ACTION_MENU,
  HIDE_ACTION_MENU,
  TASK_FORM_TOOGLE_LOADING,
  DELETE_TASK,
  SET_USER_LIST,
  SET_PROJECTS,
  SET_PRIORITIES,
  OPEN_TASKS_FORM,
  CLOSE_PROJECT_FORM,
  EDIT_SELECTED_PROJECT,
  PROJECT_FORM_TOOGLE_LOADING,
  ADD_NEW_PROJECT,
  OPEN_CATEGORIES_FORM,
  CLOSE_CATEGORIES_FORM,
  EDIT_SELECTED_CATEGORY,
  CATEGORIES_FORM_TOOGLE_LOADING,
  ADD_NEW_CATEGORY,
  UPDATE_CATEGORY,
  DELETE_CATEGORY,
  SET_DEPARTMENTS,
  ADD_NEW_SERVICE,
  UPDATE_SERVICE,
  DELETE_SERVICE,
  SET_DEFAULT_TASK_FORM_OPTIONS,
  SHOW_BOTTOM_TOOLBAR,
  HIDE_BOTTOM_TOOLBAR,
  OPEN_TASK_IN_MODAL,
  CLOSE_TASK_IN_MODAL,
  CLOSE_ALL_TASKS_IN_MODAL,
  OPEN_DEPARTMENTS_FORM,
  CLOSE_DEPARTMENT_FORM,
  EDIT_SELECTED_DEPARTMENT,
  DEPARTMENT_FORM_TOOGLE_LOADING,
  ADD_NEW_DEPARTMENT,
  OPEN_USERS_FORM,
  CLOSE_USERS_FORM,
  EDIT_SELECTED_USER,
  USERS_FORM_TOOGLE_LOADING,
  ADD_NEW_USER,
  UPDATE_DEPARTMENT,
  DELETE_DEPARTMENT,
  UPDATE_PROJECT,
  DELETE_PROJECT,
  SET_ESTABLISHMENT_INFO,
  UPDATE_ESTABLISHMENT_INFO,
  SET_ESTABLISHMENT_SETTINGS,
  UPDATE_ESTABLISHMENT_SETTINGS,
  SET_COLUMN_SETTINGS,
  UPDATE_USER,
  SET_TASK_TEXT_SEARCH_QUERY,
  SET_TASK_FILTER_SEARCH_QUERY,
  EDIT_SELECTED_CHECKLIST,
  OPEN_CHECKLISTS_FORM,
  CLOSE_CHECKLIST_FORM,
  CHECKLIST_FORM_TOOGLE_LOADING,
  SET_ALL_CHECKLISTS,
  ADD_NEW_CHECKLIST,
  UPDATE_CHECKLIST,
  DELETE_CHECKLIST,
  ADD_CHECKLIST_QUESTION,
  UPDATE_CHECKLIST_QUESTION,
  OPEN_ANSWERS_FORM,
  CLOSE_ANSWER_FORM,
  EDIT_SELECTED_ANSWER,
  ANSWER_FORM_TOOGLE_LOADING,
  OPEN_QUESTIONS_FORM,
  CLOSE_QUESTION_FORM,
  EDIT_SELECTED_QUESTION,
  QUESTION_FORM_TOOGLE_LOADING,
  DELETE_CHECKLIST_QUESTION,
  SET_REPORT_SHARED_DATA,
  SET_ALL_ESTABLISHMENT_HOLIDAYS,
  ADD_ESTABLISHMENT_HOLIDAY,
  UPDATE_ESTABLISHMENT_HOLIDAY,
  DELETE_ESTABLISHMENT_HOLIDAY,
  CUSTOM_TASKS_FORM,
  SET_CHATS,
  ADD_CHAT,
  CLEAR_CURRENT_CHAT,
  SET_CURRENT_CHAT,
  SET_MESSAGES,
  SET_ALL_ROUTES,
  UPDATE_ROUTE,
  SET_COUNTRIES,
  SET_CITIES,
  SET_PROVINCES,
  SET_TOTAL_TASKS,
  DELETE_USER,
  OPEN_HOURS_FORM,
  CLOSE_HOURS_FORM,
  EDIT_SELECTED_HOURS,
  HOURS_FORM_TOOGLE_LOADING,
  SET_HOURS,
  ADD_NEW_HOURS,
  UPDATE_HOURS,
  DELETE_HOURS,
  VIEW_HOURS,
  CLOSE_VIEW_HOURS,
  SET_STATISTICS_LIST,
  SET_NOTIFICATION_LIST,
  ADD_NEW_NOTIFICATION,
  UPDATE_NOTIFICATION,
  DELETE_NOTIFICATION,
  SET_UPDATES,
  SET_DELETED_TASKS,
  SET_FINISHED_TASKS,
  SET_FETCHED_TASKS,
} from "./../../constants";
import { createCrudReducers } from "../../utils/crudGenerator";

/* BASIC */
export const authorization = handleActions(
  {
    [AUTH]: (state, action) => action.payload,
    [LOGOUT]: () => ({ auth: false }),
  },
  null
);

/* PROFILE */
export const profile = handleActions(
  {
    [SET_PROFILE]: (state, action) => action.payload,
  },
  null
);

/* STATISTICS */
export const stadistics = handleActions(
  {
    [SET_STATISTICS_LIST]: (state, action) => action.payload,
  },
  null
);

/* ESTABLISHMENT */
export const establishment = handleActions(
  {
    [SET_ESTABLISHMENT_INFO]: (s, action) => ({ ...s, info: action.payload }),
    [UPDATE_ESTABLISHMENT_INFO]: (s, action) => ({
      ...s,
      info: action.payload,
    }),
    [SET_ESTABLISHMENT_SETTINGS]: (s, action) => ({
      ...s,
      settings: action.payload,
    }),
    [UPDATE_ESTABLISHMENT_SETTINGS]: (s, action) => ({
      ...s,
      settings: action.payload,
    }),
  },
  { info: null, settings: { skipHolidays: null, skipWeekend: null } }
);

/* TASKS */
export const tasks = handleActions(
  {
    [SET_ALL_TASKS]: (state, action) => action.payload,
    [ADD_NEW_TASK]: (state, action) => {
      const { _id } = action.payload;
      return { ...state, [_id]: action.payload };
    },
    [UPDATE_TASK]: (state, action) => {
      const { _id } = action.payload;
      return { ...state, [_id]: action.payload };
    },
    [DELETE_TASK]: (state, action) => {
      const newState = { ...state };
      delete newState[action.payload];
      return newState;
    },
  },
  null
);

/* TASKS */
export const allTasks = handleActions(
  {
    [SET_TOTAL_TASKS]: (state, action) => action.payload,
  },
  null
);

export const finishedTasks = handleActions(
  {
    [SET_FINISHED_TASKS]: (state, action) => action.payload,
  },
  false
);

/* TASKS - MODAL VIEWER */
export const tasksModalViewer = handleActions(
  {
    [OPEN_TASK_IN_MODAL]: (state, action) => {
      const newState = [...state];
      if (newState.indexOf(action.payload) === -1) {
        newState.push(action.payload);
        return newState;
      } else {
        return state;
      }
    },
    [CLOSE_TASK_IN_MODAL]: (state, action) => [
      ...state.filter((taskId) => taskId !== action.payload),
    ],
    [CLOSE_ALL_TASKS_IN_MODAL]: () => [],
  },
  []
);

/* TASKS - OPTS */
export const tasksOpts = handleActions(
  {
    /* Default Task Form Settings */
    [SET_DEFAULT_TASK_FORM_OPTIONS]: (state, action) => ({
      ...state,
      defaultOptions: action.payload,
    }),

    /* Status */
    [SET_STATUS_SETTINGS]: (state, action) => ({
      ...state,
      status: action.payload,
    }),

    /* User list */
    [SET_USER_LIST]: (state, action) => ({ ...state, users: action.payload }),
    [ADD_NEW_USER]: (state, action) => ({
      ...state,
      users: [...state.users, action.payload],
    }),
    [UPDATE_USER]: (state, action) => {
      const newState = {
        ...state,
        users: unionBy([action.payload], state.users || [], "value"),
      };
      return newState;
    },
    [DELETE_USER]: (state, action) => {
      let users = { ...state.users };
      users = keyBy(users, "_id");
      delete users[action.payload];
      const newState = { ...state, users: Object.values(users) };
      return newState;
    },
    /* Priorities */
    [SET_PRIORITIES]: (state, action) => ({
      ...state,
      priorities: action.payload,
    }),

    /* Categories */
    [SET_CATEGORIES]: (state, action) => ({
      ...state,
      categories: action.payload,
    }),
    [ADD_NEW_CATEGORY]: (state, action) => {
      const { _id } = action.payload;
      const newState = {
        ...state,
        categories: {
          ...state.categories,
          [_id]: action.payload,
        },
      };
      return newState;
    },
    [UPDATE_CATEGORY]: (state, action) => {
      const newState = {
        ...state,
        categories: {
          ...state.categories,
          [action.payload._id]: action.payload,
        },
      };
      return newState;
    },
    [DELETE_CATEGORY]: (state, action) => {
      const newState = {
        ...state,
        categories: {
          ...state.categories,
          [action.payload._id]: action.payload,
        },
      };
      return newState;
    },

    /* Services */
    [SET_SERVICES]: (state, action) => ({ ...state, services: action.payload }),
    [ADD_NEW_SERVICE]: (state, action) => {
      const newState = {
        ...state,
        services: {
          ...state.services,
          [action.payload._id]: action.payload,
        },
      };
      return newState;
    },
    [UPDATE_SERVICE]: (state, action) => {
      const newState = {
        ...state,
        services: {
          ...state.services,
          [action.payload._id]: action.payload,
        },
      };
      return newState;
    },
    [DELETE_SERVICE]: (state, action) => {
      const services = { ...state.services };
      delete services[action.payload];
      const newState = { ...state, services };
      return newState;
    },

    /* Departments */
    [SET_DEPARTMENTS]: (state, action) => ({
      ...state,
      departments: action.payload,
    }),
    [ADD_NEW_DEPARTMENT]: (state, action) => {
      const newState = {
        ...state,
        departments: {
          ...state.departments,
          [action.payload._id]: action.payload,
        },
      };
      return newState;
    },
    [UPDATE_DEPARTMENT]: (state, action) => {
      const newState = {
        ...state,
        departments: {
          ...state.departments,
          [action.payload._id]: action.payload,
        },
      };
      return newState;
    },
    [DELETE_DEPARTMENT]: (state, action) => {
      const departments = { ...state.departments };
      delete departments[action.payload];
      const newState = { ...state, departments };
      return newState;
    },

    /* Projects */
    [SET_PROJECTS]: (state, action) => ({ ...state, projects: action.payload }),
    [ADD_NEW_PROJECT]: (state, action) => {
      const newState = {
        ...state,
        projects: {
          ...state.projects,
          [action.payload._id]: action.payload,
        },
      };
      return newState;
    },
    [UPDATE_PROJECT]: (state, action) => {
      const newState = {
        ...state,
        projects: {
          ...state.projects,
          [action.payload._id]: action.payload,
        },
      };
      return newState;
    },
    [DELETE_PROJECT]: (state, action) => {
      const projects = { ...state.projects };
      delete projects[action.payload];
      const newState = { ...state, projects };
      return newState;
    },
  },
  null
);

/* TASKS - SERACH */
export const taskSearch = handleActions(
  {
    [SET_TASK_TEXT_SEARCH_QUERY]: (s, action) => ({
      ...s,
      text: action.payload,
    }),
    [SET_TASK_FILTER_SEARCH_QUERY]: (s, action) => ({
      ...s,
      filter: action.payload,
    }),
  },
  { text: "", filter: null }
);

export const deletedTasks = handleActions(
  {
    [SET_DELETED_TASKS]: (state, action) => action.payload,
  },
  null
);

export const fetchedTasks = handleActions(
  {
    [SET_FETCHED_TASKS]: (state, action) => action.payload,
  },
  null
);

/* CHECKLISTS */
export const checklists = handleActions(
  {
    [SET_ALL_CHECKLISTS]: (state, action) => action.payload,
    [ADD_NEW_CHECKLIST]: (state, action) => {
      const { _id } = action.payload;
      return { ...state, [_id]: action.payload };
    },
    [UPDATE_CHECKLIST]: (state, action) => {
      const { _id } = action.payload;
      return { ...state, [_id]: action.payload };
    },
    [DELETE_CHECKLIST]: (state, action) => {
      const newState = { ...state };
      delete newState[action.payload];
      return newState;
    },
    [ADD_CHECKLIST_QUESTION]: (state, action) => {
      const { checklistId, question } = action.payload;
      const newState = {
        ...state,
        [checklistId]: {
          ...state[checklistId],
          questions: [question, ...state[checklistId]?.questions],
        },
      };
      return newState;
    },
    [UPDATE_CHECKLIST_QUESTION]: (state, action) => {
      const { checklistId, question } = action.payload;
      const newState = {
        ...state,
        [checklistId]: {
          ...state[checklistId],
          questions: state[checklistId].questions.map((q) =>
            q._id === question?._id ? question : q
          ),
        },
      };
      return newState;
    },
    [DELETE_CHECKLIST_QUESTION]: (state, action) => {
      const { checklistId, questionId } = action.payload;
      const newState = { ...state };
      newState[checklistId].questions =
        newState[checklistId]?.questions?.filter((q) => q?.id !== questionId) ||
        [];
      return newState;
    },
  },
  null
);

/* FORMS */
export const taskForm = handleActions(
  {
    [OPEN_TASKS_FORM]: () => ({ show: true, data: null, loading: false }),
    [CUSTOM_TASKS_FORM]: (s, action) => ({
      show: true,
      data: null,
      loading: false,
      dates: action.payload,
    }),
    [CLOSE_TASK_FORM]: () => ({
      show: false,
      data: null,
      loading: false,
      dates: null,
    }),
    [EDIT_SELECTED_TASK]: (s, action) => ({
      show: true,
      data: action.payload,
      loading: false,
      dates: null,
    }),
    [TASK_FORM_TOOGLE_LOADING]: (state) => ({
      ...state,
      loading: !state.loading,
    }),
  },
  { show: false, data: null, loading: false, dates: null }
);

export const checklistForm = handleActions(
  {
    [OPEN_CHECKLISTS_FORM]: () => ({ show: true, data: null, loading: false }),
    [CLOSE_CHECKLIST_FORM]: () => ({ show: false, data: null, loading: false }),
    [EDIT_SELECTED_CHECKLIST]: (s, action) => ({
      show: true,
      data: action.payload,
      loading: false,
    }),
    [CHECKLIST_FORM_TOOGLE_LOADING]: (state) => ({
      ...state,
      loading: !state.loading,
    }),
  },
  { show: false, data: null, loading: false }
);

export const answersForm = handleActions(
  {
    [OPEN_ANSWERS_FORM]: () => ({ show: true, data: null, loading: false }),
    [CLOSE_ANSWER_FORM]: () => ({ show: false, data: null, loading: false }),
    [EDIT_SELECTED_ANSWER]: (s, action) => ({
      show: true,
      data: action.payload,
      loading: false,
    }),
    [ANSWER_FORM_TOOGLE_LOADING]: (state) => ({
      ...state,
      loading: !state.loading,
    }),
  },
  { show: false, data: null, loading: false }
);

export const questionsForm = handleActions(
  {
    [OPEN_QUESTIONS_FORM]: () => ({ show: true, data: null, loading: false }),
    [CLOSE_QUESTION_FORM]: () => ({ show: false, data: null, loading: false }),
    [EDIT_SELECTED_QUESTION]: (s, action) => ({
      show: true,
      data: action.payload,
      loading: false,
    }),
    [QUESTION_FORM_TOOGLE_LOADING]: (state) => ({
      ...state,
      loading: !state.loading,
    }),
  },
  { show: false, data: null, loading: false }
);

export const projectForm = handleActions(
  {
    [OPEN_PROJECTS_FORM]: () => ({ show: true, data: null, loading: false }),
    [CLOSE_PROJECT_FORM]: () => ({ show: false, data: null, loading: false }),
    [EDIT_SELECTED_PROJECT]: (s, action) => ({
      show: true,
      data: action.payload,
      loading: false,
    }),
    [PROJECT_FORM_TOOGLE_LOADING]: (state) => ({
      ...state,
      loading: !state.loading,
    }),
  },
  { show: false, data: null, loading: false }
);

export const departmentForm = handleActions(
  {
    [OPEN_DEPARTMENTS_FORM]: () => ({ show: true, data: null, loading: false }),
    [CLOSE_DEPARTMENT_FORM]: () => ({
      show: false,
      data: null,
      loading: false,
    }),
    [EDIT_SELECTED_DEPARTMENT]: (s, action) => ({
      show: true,
      data: action.payload,
      loading: false,
    }),
    [DEPARTMENT_FORM_TOOGLE_LOADING]: (state) => ({
      ...state,
      loading: !state.loading,
    }),
  },
  { show: false, data: null, loading: false }
);

export const categoriesForm = handleActions(
  {
    [OPEN_CATEGORIES_FORM]: (state) => ({
      show: !state.show,
      data: null,
      loading: false,
    }),
    [CLOSE_CATEGORIES_FORM]: () => ({
      show: false,
      data: null,
      loading: false,
    }),
    [EDIT_SELECTED_CATEGORY]: (s, action) => ({
      show: true,
      data: action.payload,
      loading: false,
    }),
    [CATEGORIES_FORM_TOOGLE_LOADING]: (state) => ({
      ...state,
      loading: !state.loading,
    }),
  },
  { show: false, data: null, loading: false }
);

export const usersForm = handleActions(
  {
    [OPEN_USERS_FORM]: (state) => ({
      show: !state.show,
      data: null,
      loading: false,
    }),
    [CLOSE_USERS_FORM]: () => ({ show: false, data: null, loading: false }),
    [EDIT_SELECTED_USER]: (s, action) => ({
      show: true,
      data: action.payload,
      loading: false,
    }),
    [USERS_FORM_TOOGLE_LOADING]: (state) => ({
      ...state,
      loading: !state.loading,
    }),
  },
  { show: false, data: null, loading: false }
);

export const countries = handleActions(
  {
    [SET_COUNTRIES]: (s, action) => action.payload,
  },
  null
);

export const cities = handleActions(
  {
    [SET_CITIES]: (s, action) => action.payload,
  },
  null
);

export const provinces = handleActions(
  {
    [SET_PROVINCES]: (s, action) => action.payload,
  },
  null
);

/* COLUMN SETTINGS */
export const columnSettings = handleAction(
  SET_COLUMN_SETTINGS,
  (s, action) => action.payload,
  DEFAULT_TASKS_COLUMNS
);

/* CALENDAR */
export const calendar = handleActions(
  {
    [SET_ALL_ESTABLISHMENT_HOLIDAYS]: (state, action) => {
      const newState = {
        ...state,
        holidays: {
          ...state.holidays,
          establishment: action.payload,
        },
      };
      return newState;
    },
    [ADD_ESTABLISHMENT_HOLIDAY]: (state, action) => {
      const newState = {
        ...state,
        holidays: {
          ...state.holidays,
          establishment: [...state.holidays.establishment, action.payload],
        },
      };
      return newState;
    },
    [UPDATE_ESTABLISHMENT_HOLIDAY]: (state, action) => {
      const newState = {
        ...state,
        holidays: {
          ...state.holidays,
          establishment: state.holidays.establishment.map((h) => {
            if (h?._id === action.payload._id) {
              return action.payload;
            }
            return h;
          }),
        },
      };
      return newState;
    },
    [DELETE_ESTABLISHMENT_HOLIDAY]: (state, action) => {
      const newState = {
        ...state,
        holidays: {
          ...state.holidays,
          establishment: state.holidays.establishment.filter(
            (h) => h?._id !== action.payload
          ),
        },
      };
      return newState;
    },
  },
  { holidays: { establishment: [], user: [] } }
);

/* APP */
export const currentRoute = handleAction(
  SET_CURRENT_ROUTE,
  (state, action) => action.payload,
  {}
);

export const app = handleActions(
  {
    [SET_SIDE_MENU_STATE]: (state, action) => {
      return { ...state, isCollapsedSideMenu: action.payload };
    },
    [SET_REPORT_SHARED_DATA]: (state, action) => {
      const newState = {
        ...state,
        reportSharedData: {
          ...state.reportSharedData,
          ...action.payload,
        },
      };
      return newState;
    },
  },
  {
    isCollapsedSideMenu: true,
    reportSharedData: { currentDateFilter: defaultDateFilter },
  }
);

export const notifications = handleActions(
  {
    [SHOW_NOTIFICATION]: (state, action) => action.payload,
    [CLOSE_NOTIFICATION]: (state, action) => ({
      show: false,
      status: null,
      message: null,
    }),
  },
  { show: false, status: null, message: null }
);

export const actionsMenu = handleActions(
  {
    [SHOW_ACTION_MENU]: () => true,
    [HIDE_ACTION_MENU]: () => false,
  },
  false
);

export const toolbar = handleActions(
  {
    [SHOW_BOTTOM_TOOLBAR]: () => true,
    [HIDE_BOTTOM_TOOLBAR]: () => false,
  },
  true
);

export const chats = handleActions(
  {
    [SET_CHATS]: (state, action) => action.payload,
    [ADD_CHAT]: (state, action) => {
      const newState = state;
      newState.push(action.payload);
      return newState;
    },
  },
  null
);

export const currentChat = handleActions(
  {
    [SET_CURRENT_CHAT]: (state, action) => action.payload,
    [CLEAR_CURRENT_CHAT]: () => null,
  },
  null
);

export const messages = handleActions(
  {
    [SET_MESSAGES]: (state, action) => {
      const id = action.payload.id;
      const messages = action.payload.messages;
      const newState = { ...state, [id]: { messages } };
      return newState;
    },
  },
  null
);

export const routes = handleActions(
  {
    [SET_ALL_ROUTES]: (state, action) => action.payload,
    [UPDATE_ROUTE]: (state, action) => {
      const { keyapp } = action.payload;
      return { ...state, [keyapp]: action.payload };
    },
  },
  null
);

export const clientsForm = handleActions(
  {
    [OPEN_CLIENTS_FORM]: (state) => ({
      show: !state.show,
      data: null,
      loading: false,
    }),
    [CLOSE_CLIENTS_FORM]: () => ({ show: false, data: null, loading: false }),
    [EDIT_SELECTED_CLIENT]: (s, action) => ({
      show: true,
      data: action.payload,
      loading: false,
    }),
    [CLIENTS_FORM_TOOGLE_LOADING]: (state) => ({
      ...state,
      loading: !state.loading,
    }),
  },
  { show: false, data: null, loading: false }
);

export const clients = handleActions(
  {
    [SET_CLIENTS]: (state, action) => action.payload,
    [ADD_NEW_CLIENTS]: (state, action) => {
      const newState = {
        ...state,
        [action.payload.value]: action.payload,
      };
      return newState;
    },
    [UPDATE_CLIENTS]: (state, action) => {
      const newState = {
        ...state,
        [action.payload.value]: action.payload,
      };
      return newState;
    },
    [DELETE_CLIENT]: (state, action) => {
      delete state[action.payload.value];
      return { ...state };
    },
  },
  null
);

export const providersForm = handleActions(
  {
    [OPEN_PROVIDERS_FORM]: (state) => ({
      show: !state.show,
      data: null,
      loading: false,
    }),
    [CLOSE_PROVIDERS_FORM]: () => ({ show: false, data: null, loading: false }),
    [EDIT_SELECTED_PROVIDER]: (s, action) => ({
      show: true,
      data: action.payload,
      loading: false,
    }),
    [PROVIDERS_FORM_TOOGLE_LOADING]: (state) => ({
      ...state,
      loading: !state.loading,
    }),
  },
  { show: false, data: null, loading: false }
);

export const providers = handleActions(
  {
    [SET_PROVIDERS]: (state, action) => action.payload,
    [ADD_NEW_PROVIDERS]: (state, action) => {
      const newState = {
        ...state,
        [action.payload.value]: action.payload,
      };
      return newState;
    },
    [UPDATE_PROVIDERS]: (state, action) => {
      const newState = {
        ...state,
        [action.payload.value]: action.payload,
      };
      return newState;
    },
    [DELETE_PROVIDER]: (state, action) => {
      delete state[action.payload.value];
      return { ...state };
    },
  },
  null
);

export const resourcesForm = handleActions(
  {
    [OPEN_RESOURCES_FORM]: (state) => ({
      show: !state.show,
      data: null,
      loading: false,
    }),
    [CLOSE_RESOURCES_FORM]: () => ({ show: false, data: null, loading: false }),
    [EDIT_SELECTED_RESOURCE]: (s, action) => ({
      show: true,
      data: action.payload,
      loading: false,
    }),
    [RESOURCES_FORM_TOOGLE_LOADING]: (state) => ({
      ...state,
      loading: !state.loading,
    }),
  },
  { show: false, data: null, loading: false }
);

export const resources = handleActions(
  {
    [SET_RESOURCES]: (state, action) => action.payload,
    [ADD_NEW_RESOURCES]: (state, action) => {
      const newState = {
        ...state,
        [action.payload.value]: action.payload,
      };
      return newState;
    },
    [UPDATE_RESOURCES]: (state, action) => {
      const newState = {
        ...state,
        [action.payload.value]: action.payload,
      };
      return newState;
    },
    [DELETE_RESOURCE]: (state, action) => {
      delete state[action.payload];
      return { ...state };
    },
  },
  null
);

export const unitsForm = handleActions(
  {
    [OPEN_UNITS_FORM]: (state) => ({
      show: !state.show,
      data: null,
      loading: false,
    }),
    [CLOSE_UNITS_FORM]: () => ({ show: false, data: null, loading: false }),
    [EDIT_SELECTED_UNIT]: (s, action) => ({
      show: true,
      data: action.payload,
      loading: false,
    }),
    [UNITS_FORM_TOOGLE_LOADING]: (state) => ({
      ...state,
      loading: !state.loading,
    }),
  },
  { show: false, data: null, loading: false }
);

export const units = handleActions(
  {
    [SET_UNITS]: (state, action) => action.payload,
    [ADD_NEW_UNITS]: (state, action) => {
      const newState = {
        ...state,
        [action.payload.value]: action.payload,
      };
      return newState;
    },
    [UPDATE_UNITS]: (state, action) => {
      const newState = {
        ...state,
        [action.payload.value]: action.payload,
      };
      return newState;
    },
    [DELETE_UNIT]: (state, action) => {
      delete state[action.payload];
      return { ...state };
    },
  },
  null
);

export const updates = handleActions(
  {
    [SET_UPDATES]: (state, action) => action.payload,
  },
  null
);

export const productsForm = handleActions(
  {
    [OPEN_PRODUCTS_FORM]: (state) => ({
      show: !state.show,
      data: null,
      loading: false,
    }),
    [CLOSE_PRODUCTS_FORM]: () => ({ show: false, data: null, loading: false }),
    [EDIT_SELECTED_PRODUCT]: (s, action) => ({
      show: true,
      data: action.payload,
      loading: false,
    }),
    [PRODUCTS_FORM_TOOGLE_LOADING]: (state) => ({
      ...state,
      loading: !state.loading,
    }),
  },
  { show: false, data: null, loading: false }
);

export const products = handleActions(
  {
    [SET_PRODUCTS]: (state, action) => action.payload,
    [ADD_NEW_PRODUCTS]: (state, action) => {
      const newState = {
        ...state,
        [action.payload.value]: action.payload,
      };
      return newState;
    },
    [UPDATE_PRODUCTS]: (state, action) => {
      const newState = {
        ...state,
        [action.payload.value]: action.payload,
      };
      return newState;
    },
    [DELETE_PRODUCT]: (state, action) => {
      delete state[action.payload];
      return { ...state };
    },
  },
  null
);

export const workOrdersForm = handleActions(
  {
    [OPEN_WORKORDERS_FORM]: (state, action) => ({
      show: !state.show,
      data: action.payload,
      loading: false,
    }),
    [CLOSE_WORKORDERS_FORM]: () => ({
      show: false,
      data: null,
      loading: false,
    }),
    [EDIT_SELECTED_WORKORDER]: (s, action) => ({
      show: true,
      data: action.payload,
      loading: false,
    }),
    [WORKORDERS_FORM_TOOGLE_LOADING]: (state) => ({
      ...state,
      loading: !state.loading,
    }),
  },
  { show: false, data: null, loading: false }
);

export const workOrders = handleActions(
  {
    [SET_WORKORDERS]: (state, action) => action.payload,
    [ADD_NEW_WORKORDERS]: (state, action) => {
      const newState = {
        ...state,
        [action.payload.value]: action.payload,
      };
      return newState;
    },
    [UPDATE_WORKORDERS]: (state, action) => {
      const newState = {
        ...state,
        [action.payload.value]: action.payload,
      };
      return newState;
    },
    [DELETE_WORKORDER]: (state, action) => {
      delete state[action.payload];
      return { ...state };
    },
  },
  null
);

export const alertsForm = handleActions(
  {
    [OPEN_ALERTS_FORM]: (state, action) => ({
      show: !state.show,
      data: action.payload,
      loading: false,
    }),
    [CLOSE_ALERTS_FORM]: () => ({
      show: false,
      data: null,
      loading: false,
    }),
    [EDIT_SELECTED_ALERT]: (s, action) => ({
      show: true,
      data: action.payload,
      loading: false,
    }),
    [ALERTS_FORM_TOOGLE_LOADING]: (state) => ({
      ...state,
      loading: !state.loading,
    }),
  },
  { show: false, data: null, loading: false }
);

export const alerts = handleActions(
  {
    [SET_ALERTS]: (state, action) => action.payload,
    [ADD_NEW_ALERTS]: (state, action) => {
      const newState = {
        ...state,
        [action.payload.value]: action.payload,
      };
      return newState;
    },
    [UPDATE_ALERTS]: (state, action) => {
      const newState = {
        ...state,
        [action.payload.value]: action.payload,
      };
      return newState;
    },
    [DELETE_ALERT]: (state, action) => {
      delete state[action.payload];
      return { ...state };
    },
  },
  null
);

export const hoursForm = handleActions(
  {
    [OPEN_HOURS_FORM]: (state, action) => ({
      ...state,
      show: !state.show,
      data: action.payload,
      loading: false,
    }),
    [CLOSE_HOURS_FORM]: (state) => ({
      ...state,
      show: false,
      data: null,
      loading: false,
    }),
    [EDIT_SELECTED_HOURS]: (s, action) => ({
      ...s,
      show: true,
      data: action.payload,
      loading: false,
    }),
    [VIEW_HOURS]: (s, action) => ({
      ...s,
      showList: true,
      _id: action.payload,
      loading: false,
    }),
    [CLOSE_VIEW_HOURS]: (s, action) => ({
      ...s,
      showList: false,
      data: action.payload,
      loading: false,
    }),
    [HOURS_FORM_TOOGLE_LOADING]: (state) => ({
      ...state,
      loading: !state.loading,
    }),
  },
  { show: false, data: null, loading: false, showList: false, _id: null }
);

export const hours = handleActions(
  {
    [SET_HOURS]: (state, action) => action.payload,
    [ADD_NEW_HOURS]: (state, action) => {
      const newState = {
        ...state,
        [action.payload.value]: action.payload,
      };
      return newState;
    },
    [UPDATE_HOURS]: (state, action) => {
      const newState = {
        ...state,
        [action.payload.value]: action.payload,
      };
      return newState;
    },
    [DELETE_HOURS]: (state, action) => {
      delete state[action.payload];
      return { ...state };
    },
  },
  null
);

export const notificationsPush = handleActions(
  {
    [SET_NOTIFICATION_LIST]: (state, action) => action.payload,
    [ADD_NEW_NOTIFICATION]: (state, action) => {
      const newState = {
        ...state,
        [action.payload.value]: action.payload,
      };
      return newState;
    },
    [UPDATE_NOTIFICATION]: (state, action) => {
      const newState = {
        ...state,
        [action.payload.value]: action.payload,
      };
      return newState;
    },
    [DELETE_NOTIFICATION]: (state, action) => {
      action.payload.forEach((number) => {
        delete state[number];
      });
      return { ...state };
    },
  },
  null
);
const incidenceTypeReducers = createCrudReducers('INCIDENCETYPE');

export const incidenceTypes = incidenceTypeReducers.list;
export const incidenceTypesForm = incidenceTypeReducers.form;
const incidenceReducers = createCrudReducers('INCIDENCE');

export const incidences = incidenceReducers.list;
export const incidencesForm = incidenceReducers.form;
const chatGruopReducers = createCrudReducers('CHATGRUOP');

export const chatGruops = chatGruopReducers.list;
export const chatGruopsForm = chatGruopReducers.form;
