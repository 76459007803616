import { combineReducers } from "redux";
import {
  authorization,
  profile,
  tasks,
  currentRoute,
  app,
  taskForm,
  tasksOpts,
  notifications,
  actionsMenu,
  projectForm,
  categoriesForm,
  toolbar,
  checklistForm,
  tasksModalViewer,
  departmentForm,
  usersForm,
  answersForm,
  establishment,
  columnSettings,
  taskSearch,
  checklists,
  questionsForm,
  calendar,
  currentChat,
  chats,
  messages,
  routes,
  clients,
  clientsForm,
  countries,
  cities,
  provinces,
  providers,
  providersForm,
  resourcesForm,
  resources,
  products,
  productsForm,
  workOrdersForm,
  workOrders,
  allTasks,
  hours,
  hoursForm,
  stadistics,
  alerts,
  alertsForm,
  notificationsPush,
  updates,
  units,
  unitsForm,
  deletedTasks,
  finishedTasks,
  fetchedTasks,
  incidenceTypes,
  incidenceTypesForm,
  incidences,
  incidencesForm,
  chatGruops,
  chatGruopsForm,
} from "./Reducers";

export default combineReducers({
  chatGruops,
  chatGruopsForm,
  incidences,
  incidencesForm,
  incidenceTypes,
  incidenceTypesForm,
  app,
  authorization,
  currentRoute,
  profile,
  establishment,
  calendar,
  tasks,
  taskForm,
  taskSearch,
  tasksModalViewer,
  checklists,
  checklistForm,
  questionsForm,
  answersForm,
  projectForm,
  departmentForm,
  categoriesForm,
  usersForm,
  tasksOpts,
  columnSettings,
  notifications,
  toolbar,
  actionsMenu,
  chats,
  currentChat,
  messages,
  routes,
  clients,
  clientsForm,
  countries,
  cities,
  provinces,
  providers,
  providersForm,
  resourcesForm,
  resources,
  products,
  productsForm,
  workOrdersForm,
  workOrders,
  allTasks,
  hours,
  hoursForm,
  stadistics,
  alerts,
  alertsForm,
  notificationsPush,
  updates,
  units,
  unitsForm,
  deletedTasks,
  finishedTasks,
  fetchedTasks,
});
