/* BASIC APP CONSTANTS */
export const AUTH = "AUTH";
export const LOGOUT = "LOGOUT";
export const APP_COOKIE_NAME = "APP_COOKIE_NAME";
export const USER_COOKIE_NAME = "USER_COOKIE_NAME";
export const PASS_COOKIE_NAME = "PASS_COOKIE_NAME";
export const USER_ID_COOKIE_NAME = "USER_ID_COOKIE_NAME";
export const SET_CURRENT_ROUTE = "SET_CURRENT_ROUTE";
export const SET_SIDE_MENU_STATE = "SET_SIDE_MENU_STATE";
export const SHOW_NOTIFICATION = "SHOW_NOTIFICATION";
export const CLOSE_NOTIFICATION = "CLOSE_NOTIFICATION";
export const SHOW_ACTION_MENU = "SHOW_ACTION_MENU";
export const HIDE_ACTION_MENU = "HIDE_ACTION_MENU";
export const SHOW_BOTTOM_TOOLBAR = "SHOW_BOTTOM_TOOLBAR";
export const HIDE_BOTTOM_TOOLBAR = "HIDE_BOTTOM_TOOLBAR";
/* <---- BASIC APP CONSTANTS ----> */

/* CHAT */
export const SET_CHATS = "SET_CHATS";
export const ADD_CHAT = "ADD_CHAT";
export const SET_CURRENT_CHAT = "SET_CURRENT_CHAT";
export const CLEAR_CURRENT_CHAT = "CLEAR_CURRENT_CHAT";
export const SET_MESSAGES = "SET_MESSAGES";

/* USER */
export const SET_PROFILE = "SET_PROFILE";
/* <---- USER ----> */

/* UPDATES */
export const SET_UPDATES = "SET_UPDATES";
/* <---- UPDATES ----> */

/* COUNTRIES */
export const SET_COUNTRIES = "SET_COUNTRIES";
/* <---- COUNTRIES ----> */

/* CITIES */
export const SET_CITIES = "SET_CITIES";
/* <---- CITIES ----> */

/* PROVINCES */
export const SET_PROVINCES = "SET_PROVINCES";
/* <---- PROVINCES ----> */

/* ESTABLISHMENT */
export const SET_ESTABLISHMENT_INFO = "SET_ESTABLISHMENT_INFO";
export const UPDATE_ESTABLISHMENT_INFO = "UPDATE_ESTABLISHMENT_INFO";
export const SET_ESTABLISHMENT_SETTINGS = "SET_ESTABLISHMENT_SETTINGS";
export const UPDATE_ESTABLISHMENT_SETTINGS = "UPDATE_ESTABLISHMENT_SETTINGS";
/* <---- ESTABLISHMENT ----> */

/* CHECKLISTS */
export const SET_ALL_CHECKLISTS = "SET_ALL_CHECKLISTS";
export const ADD_NEW_CHECKLIST = "ADD_NEW_CHECKLIST";
export const UPDATE_CHECKLIST = "UPDATE_CHECKLIST";
export const EDIT_SELECTED_CHECKLIST = "EDIT_SELECTED_CHECKLIST";
export const DELETE_CHECKLIST = "DELETE_CHECKLIST";
export const OPEN_CHECKLISTS_FORM = "OPEN_CHECKLISTS_FORM";
export const CLOSE_CHECKLIST_FORM = "CLOSE_CHECKLIST_FORM";
export const CHECKLIST_FORM_TOOGLE_LOADING = "CHECKLIST_FORM_TOOGLE_LOADING";
/* <---- CHECKLISTS ----> */

/* CHECKLISTS - Questions */
export const ADD_CHECKLIST_QUESTION = "ADD_CHECKLIST_QUESTION";
export const UPDATE_CHECKLIST_QUESTION = "UPDATE_CHECKLIST_QUESTION";
export const DELETE_CHECKLIST_QUESTION = "DELETE_CHECKLIST_QUESTION";
/* <---- CHECKLISTS - Questions ----> */

/* <---- CHECKLISTS - Questions Form ----> */
export const OPEN_QUESTIONS_FORM = "OPEN_QUESTIONS_FORM";
export const CLOSE_QUESTION_FORM = "CLOSE_QUESTION_FORM";
export const EDIT_SELECTED_QUESTION = "EDIT_SELECTED_QUESTION";
export const QUESTION_FORM_TOOGLE_LOADING = "QUESTION_FORM_TOOGLE_LOADING";
/* <---- CHECKLISTS - Questions Form ----> */

/* <---- CHECKLISTS - Answers Form ----> */
export const OPEN_ANSWERS_FORM = "OPEN_ANSWERS_FORM";
export const CLOSE_ANSWER_FORM = "CLOSE_ANSWER_FORM";
export const EDIT_SELECTED_ANSWER = "EDIT_SELECTED_ANSWER";
export const ANSWER_FORM_TOOGLE_LOADING = "ANSWER_FORM_TOOGLE_LOADING";
/* <---- CHECKLISTS - Answers Form ----> */

/* <---- CHECKLISTS - Shared Data ----> */
export const SET_REPORT_SHARED_DATA = "SET_REPORT_SHARED_DATA";
/* <---- CHECKLISTS - Shared Data ----> */

/* TASKS - MODAL VIEWER */
export const OPEN_TASK_IN_MODAL = "OPEN_TASK_IN_MODAL";
export const CLOSE_TASK_IN_MODAL = "CLOSE_TASK_IN_MODAL";
export const CLOSE_ALL_TASKS_IN_MODAL = "CLOSE_ALL_TASKS_IN_MODAL";
/* <---- TASKS - MODAL VIEWER ----> */

/* TASKS OPTIONS */
export const SET_CATEGORIES = "SET_CATEGORIES";
export const SET_PROJECTS = "SET_PROJECTS";
export const SET_SERVICES = "SET_SERVICES";
export const SET_DEPARTMENTS = "SET_DEPARTMENTS";
export const SET_STATUS_SETTINGS = "SET_STATUS_SETTINGS";
export const SET_USER_LIST = "SET_USER_LIST";
export const SET_STATISTICS_LIST = "SET_STATISTICS_LIST";
export const SET_PRIORITIES = "SET_PRIORITIES";
export const SET_DEFAULT_TASK_FORM_OPTIONS = "SET_DEFAULT_TASK_FORM_OPTIONS";
/* <---- TASKS OPTIONS ----> */

/* TASKS SEARCH */
export const SET_TASK_TEXT_SEARCH_QUERY = "SET_TASK_TEXT_SEARCH_QUERY";
export const SET_TASK_FILTER_SEARCH_QUERY = "SET_TASK_FILTER_SEARCH_QUERY";
/* <---- TASKS SEARCH ----> */

/* TASKS */
export const SET_ALL_TASKS = "SET_ALL_TASKS";
export const SET_TOTAL_TASKS = "SET_TOTAL_TASKS";
export const SET_FINISHED_TASKS = "SET_FINISHED_TASKS";
export const ADD_NEW_TASK = "ADD_NEW_TASK";
export const UPDATE_TASK = "UPDATE_TASK";
export const EDIT_SELECTED_TASK = "EDIT_SELECTED_TASK";
export const DELETE_TASK = "DELETE_TASK";
export const OPEN_TASKS_FORM = "OPEN_TASKS_FORM";
export const CUSTOM_TASKS_FORM = "CUSTOM_TASKS_FORM";
export const CLOSE_TASK_FORM = "CLOSE_TASK_FORM";
export const TASK_FORM_TOOGLE_LOADING = "TASK_FORM_TOOGLE_LOADING";
export const SET_DELETED_TASKS = "SET_DELETED_TASKS";
export const SET_FETCHED_TASKS = "SET_FETCHED_TASKS";
/* <---- TASKS ----> */

/* PROJECTS */
export const ADD_NEW_PROJECT = "ADD_NEW_PROJECT";
export const UPDATE_PROJECT = "UPDATE_PROJECT";
export const DELETE_PROJECT = "DELETE_PROJECT";
export const OPEN_PROJECTS_FORM = "OPEN_PROJECTS_FORM";
export const CLOSE_PROJECT_FORM = "CLOSE_PROJECT_FORM";
export const EDIT_SELECTED_PROJECT = "EDIT_SELECTED_PROJECT";
export const PROJECT_FORM_TOOGLE_LOADING = "PROJECT_FORM_TOOGLE_LOADING";
/* <---- PROJECTS ----> */

/* DEPARTMENTS */
export const ADD_NEW_DEPARTMENT = "ADD_NEW_DEPARTMENT";
export const UPDATE_DEPARTMENT = "UPDATE_DEPARTMENT";
export const DELETE_DEPARTMENT = "DELETE_DEPARTMENT";
export const OPEN_DEPARTMENTS_FORM = "OPEN_DEPARTMENTS_FORM";
export const CLOSE_DEPARTMENT_FORM = "CLOSE_DEPARTMENT_FORM";
export const EDIT_SELECTED_DEPARTMENT = "EDIT_SELECTED_DEPARTMENT";
export const DEPARTMENT_FORM_TOOGLE_LOADING = "DEPARTMENT_FORM_TOOGLE_LOADING";
/* <---- DEPARTMENTS ----> */

/* CATEGORIES */
export const ADD_NEW_CATEGORY = "ADD_NEW_CATEGORY";
export const UPDATE_CATEGORY = "UPDATE_CATEGORY";
export const DELETE_CATEGORY = "DELETE_CATEGORY";
export const OPEN_CATEGORIES_FORM = "OPEN_CATEGORIES_FORM";
export const CLOSE_CATEGORIES_FORM = "CLOSE_CATEGORIES_FORM";
export const EDIT_SELECTED_CATEGORY = "EDIT_SELECTED_CATEGORY";
export const CATEGORIES_FORM_TOOGLE_LOADING = "CATEGORIES_FORM_TOOGLE_LOADING";
/* <---- CATEGORIES ----> */

/* SERVICES */
export const ADD_NEW_SERVICE = "ADD_NEW_SERVICE";
export const UPDATE_SERVICE = "UPDATE_SERVICE";
export const DELETE_SERVICE = "DELETE_SERVICE";
export const OPEN_SERVICES_FORM = "OPEN_SERVICES_FORM";
export const CLOSE_SERVICES_FORM = "CLOSE_SERVICES_FORM";
export const EDIT_SELECTED_SERVICE = "EDIT_SELECTED_SERVICE";
export const SERVICES_FORM_TOOGLE_LOADING = "SERVICES_FORM_TOOGLE_LOADING";
/* <---- SERVICES ----> */

/* USERS */
export const ADD_NEW_USER = "ADD_NEW_USER";
export const UPDATE_USER = "UPDATE_USER";
export const DELETE_USER = "DELETE_USER";
export const OPEN_USERS_FORM = "OPEN_USERS_FORM";
export const CLOSE_USERS_FORM = "CLOSE_USERS_FORM";
export const EDIT_SELECTED_USER = "EDIT_SELECTED_USER";
export const USERS_FORM_TOOGLE_LOADING = "USERS_FORM_TOOGLE_LOADING";
/* <---- USERS ----> */

/* CHATGRUOP */
export const SET_CHATGRUOP_LIST = "SET_CHATGRUOP_LIST";
export const ADD_NEW_CHATGRUOP = "ADD_NEW_CHATGRUOP";
export const UPDATE_CHATGRUOP = "UPDATE_CHATGRUOP";
export const DELETE_CHATGRUOP = "DELETE_CHATGRUOP";
export const OPEN_CHATGRUOP_FORM = "OPEN_CHATGRUOP_FORM";
export const CLOSE_CHATGRUOP_FORM = "CLOSE_CHATGRUOP_FORM";
export const EDIT_SELECTED_CHATGRUOP = "EDIT_SELECTED_CHATGRUOP";
export const CHATGRUOP_FORM_TOOGLE_LOADING = "CHATGRUOP_FORM_TOOGLE_LOADING";
/* <---- CHATGRUOP ----> */

/* INCIDENCE */
export const SET_INCIDENCE_LIST = "SET_INCIDENCE_LIST";
export const ADD_NEW_INCIDENCE = "ADD_NEW_INCIDENCE";
export const UPDATE_INCIDENCE = "UPDATE_INCIDENCE";
export const DELETE_INCIDENCE = "DELETE_INCIDENCE";
export const OPEN_INCIDENCE_FORM = "OPEN_INCIDENCE_FORM";
export const CLOSE_INCIDENCE_FORM = "CLOSE_INCIDENCE_FORM";
export const EDIT_SELECTED_INCIDENCE = "EDIT_SELECTED_INCIDENCE";
export const INCIDENCE_FORM_TOOGLE_LOADING = "INCIDENCE_FORM_TOOGLE_LOADING";
/* <---- INCIDENCE ----> */

/* INCIDENCETYPE */
export const SET_INCIDENCETYPE_LIST = "SET_INCIDENCETYPE_LIST";
export const ADD_NEW_INCIDENCETYPE = "ADD_NEW_INCIDENCETYPE";
export const UPDATE_INCIDENCETYPE = "UPDATE_INCIDENCETYPE";
export const DELETE_INCIDENCETYPE = "DELETE_INCIDENCETYPE";
export const OPEN_INCIDENCETYPE_FORM = "OPEN_INCIDENCETYPE_FORM";
export const CLOSE_INCIDENCETYPE_FORM = "CLOSE_INCIDENCETYPE_FORM";
export const EDIT_SELECTED_INCIDENCETYPE = "EDIT_SELECTED_INCIDENCETYPE";
export const INCIDENCETYPE_FORM_TOOGLE_LOADING = "INCIDENCETYPE_FORM_TOOGLE_LOADING";
/* <---- INCIDENCETYPE ----> */

/* COLUMN SETTINGS */
export const SET_COLUMN_SETTINGS = "SET_COLUMN_SETTINGS";
/* <---- COLUMN SETTINGS ----> */

/* CALENDAR */
export const SET_ALL_ESTABLISHMENT_HOLIDAYS = "SET_ALL_ESTABLISHMENT_HOLIDAYS";
export const ADD_ESTABLISHMENT_HOLIDAY = "ADD_ESTABLISHMENT_HOLIDAY";
export const UPDATE_ESTABLISHMENT_HOLIDAY = "UPDATE_ESTABLISHMENT_HOLIDAY";
export const DELETE_ESTABLISHMENT_HOLIDAY = "DELETE_ESTABLISHMENT_HOLIDAY";
/* <---- CALENDAR ----> */

/* ROUTES */
export const SET_ALL_ROUTES = "SET_ALL_ROUTES";
export const UPDATE_ROUTE = "UPDATE_ROUTE";
export const CREATE_ROUTE = "CREATE_ROUTE";
export const DELETE_ROUTE = "DELETE_ROUTE";
/* <---- ROUTES ----> */

/* HOURS */
export const SET_HOURS = "SET_HOURS";
export const ADD_NEW_HOURS = "ADD_NEW_HOURS";
export const HOURS_FORM_TOOGLE_LOADING = "HOURS_FORM_TOOGLE_LOADING";
export const UPDATE_HOURS = "UPDATE_HOURS";
export const DELETE_HOURS = "DELETE_HOURS";
export const OPEN_HOURS_FORM = "OPEN_HOURS_FORM";
export const CLOSE_HOURS_FORM = "CLOSE_HOURS_FORM";
export const EDIT_SELECTED_HOURS = "EDIT_SELECTED_HOURS";
export const VIEW_HOURS = "VIEW_HOURS";
export const CLOSE_VIEW_HOURS = "CLOSE_VIEW_HOURS";
/* <---- HOURS ----> */

/* NOTIFICATION */
export const SET_NOTIFICATION_LIST = "SET_NOTIFICATION_LIST";
export const ADD_NEW_NOTIFICATION = "ADD_NEW_NOTIFICATION";
export const UPDATE_NOTIFICATION = "UPDATE_NOTIFICATION";
export const DELETE_NOTIFICATION = "DELETE_NOTIFICATION";
export const OPEN_NOTIFICATION_FORM = "OPEN_NOTIFICATION_FORM";
export const CLOSE_NOTIFICATION_FORM = "CLOSE_NOTIFICATION_FORM";
export const EDIT_SELECTED_NOTIFICATION = "EDIT_SELECTED_NOTIFICATION";
export const NOTIFICATION_FORM_TOOGLE_LOADING =
  "NOTIFICATION_FORM_TOOGLE_LOADING";
/* <---- NOTIFICATION ----> */
