import { createCrudActions } from '../utils/crudGenerator';

const formatIncidenceTypes = (data) => {
    if (!data) {
        return [];
    }
    const formatItem = (item) => ({
        ...item,
        label: `${item.name}`,
        value: item._id,
    });

    if (Array.isArray(data)) {
        return data.map(formatItem);
    }
    return formatItem(data);
};

export const {
    fetch: fetchIncidenceTypes,
    create: createIncidenceType,
    update: updateIncidenceType,
    delete: deleteIncidenceTypes
} = createCrudActions('INCIDENCETYPE', "Tipo de incidencia", formatIncidenceTypes);