import { createCrudActions } from '../utils/crudGenerator';

const formatChatGruops = (data) => {
    if (!data) {
        return [];
    }
    const formatItem = (item) => ({
        ...item,
        label: `${item.name}`,
        value: item._id,
    });

    if (Array.isArray(data)) {
        return data.map(formatItem);
    }
    return formatItem(data);
};

export const {
    fetch: fetchChatGruops,
    create: createChatGruop,
    update: updateChatGruop,
    delete: deleteChatGruops
} = createCrudActions('CHATGRUOP', "Grupos de chat", formatChatGruops);