import { axios } from "../connection/ConnectionHandler";
import { fetchChatGruops } from "./chatGruopsActions";
import { fetchIncidences } from "./incidencesActions";
import { fetchIncidenceTypes } from "./incidenceTypesActions";
import {
  APP_COOKIE_NAME,
  AUTH,
  PASS_COOKIE_NAME,
  SET_PROFILE,
  SHOW_NOTIFICATION,
  USER_COOKIE_NAME,
  USER_ID_COOKIE_NAME,
} from "../constants";
import { API } from "../constants/api";
import { deleteCookie, getCookie, setCookie } from "../utils";
import {
  fetchCategories,
  fetchColumnSettings,
  fetchDefaultTaskFormOptions,
  fetchDepartments,
  fetchMockedData,
  fetchProjects,
  fetchServices,
  fetchUpdates,
  fetchUsers,
} from "./settingsActions";
import {
  fetchAllTasks,
} from "./tasksActions";
import { fetchAllChecklists } from "./checklistActions";
import {
  fetchEstablishmentSettings,
  fetchEstablishmentInfo,
  fetchEstbalishmentHolidays,
} from "./establishmentActions";
import { fetchAllRoutes } from "./../actions/routeAction";
import {
  fetchClients,
  fetchProviders,
} from "./clientsProvidersActions";
import { fetchResources } from "./resourcesActions";
import { fetchProducts } from "./productsActions";
import { fetchWorkOrders } from "./workOrdersActions";
import { fetchHours } from "./hoursActions";
import { fetchAlerts } from "./alertsActions";
import { fetchNotifications } from "./notificationsActions";
import { fetchUnits } from "./unitsActions";

const rememberLogin = (username, password, remember) => {
  if (remember) {
    setCookie(USER_COOKIE_NAME, username, 30);
    setCookie(PASS_COOKIE_NAME, password, 30);
  } else {
    deleteCookie(USER_COOKIE_NAME, "");
    deleteCookie(PASS_COOKIE_NAME, "");
  }
};

/* PERSISTENCY CHECK */
export const checkToken = () => {
  return async (dispatch) => {
    const token = await getCookie(APP_COOKIE_NAME);
    const userId = await getCookie(USER_ID_COOKIE_NAME);
    if (token) {
      dispatch({ type: AUTH, payload: { auth: true, token, userId } });
    }
  };
};

/* INITAL DATA LOAD */
export const loadInitialData = () => {
  return async (dispatch) => {
    dispatch(fetchAllRoutes());
		dispatch(fetchChatGruops());
		dispatch(fetchIncidences());
		dispatch(fetchIncidenceTypes());
    dispatch(fetchUserProfile());
    dispatch(fetchEstablishmentInfo());
    dispatch(fetchEstablishmentSettings());
    dispatch(fetchColumnSettings());
    dispatch(fetchDefaultTaskFormOptions());
    dispatch(fetchAllTasks());
    dispatch(fetchAllChecklists());
    dispatch(fetchUsers());
    dispatch(fetchClients());
    dispatch(fetchCategories());
    dispatch(fetchServices());
    dispatch(fetchDepartments());
    dispatch(fetchProjects());
    dispatch(fetchEstbalishmentHolidays());
    dispatch(fetchMockedData());
    dispatch(fetchProviders());
    dispatch(fetchResources());
    dispatch(fetchProducts());
    dispatch(fetchWorkOrders());
    dispatch(fetchHours());
    dispatch(fetchAlerts());
    dispatch(fetchNotifications());
    dispatch(fetchUpdates());
    dispatch(fetchUnits());
  };
};

export const fetchUserProfile = () => {
  return (dispatch) => {
    axios
      .get(API.users.profile)
      .then((response) =>
        dispatch({ type: SET_PROFILE, payload: response.data })
      )
      .catch((err) => err);
  };
};

export const setPicture = (doc) => {
  return async (dispatch) => {
    const response = await axios
      .post(API.users.picture, doc)
      .then((response) => {
        dispatch({ type: SET_PROFILE, payload: response.data });
        dispatch({
          type: SHOW_NOTIFICATION,
          payload: { show: true, status: "info", message: "Imagen guardada." },
        });
      })
      .catch((err) => err);
    return response;
  };
};

export const login = (username, password, remember) => {
  return async (dispatch) => {
    const response = await axios
      .post(API.auth.login, { username, password })
      .then((response) => {
        if (response && response.data && !response.data.error_code) {
          const { access_token } = response.data || {};
          rememberLogin(username, password, remember);
          dispatch({
            type: AUTH,
            payload: { auth: true, token: access_token },
          });
        }
        return response;
      })
      .catch((err) => err);
    return response;
  };
};

export const check2FA = (data) => {
  return async (dispatch) => {
    const response = await axios
      .post(API.auth.check2FA, data)
      .then((response) => {
        if (response && response.data && response.data.access_token) {
          const { access_token } = response.data || {};
          rememberLogin(data.username, data.password, data.remember);
          dispatch({
            type: AUTH,
            payload: { auth: true, token: access_token },
          });
        }
        return response;
      })
      .catch((err) => err);
    return response;
  };
};

export const uploadImage = (data, url) => {
  return async (dispatch) => {
    const response = await axios
      .post(url, data)
      .then((response) => {
        return response;
      })
      .catch((err) => err);
    return response;
  };
};

export const deleteImage = (url) => {
  return async (dispatch) => {
    const response = await axios
      .delete(url)
      .then((response) => {
        return response;
      })
      .catch((err) => err);
    return response;
  };
};
