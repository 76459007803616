import { createCrudActions } from '../utils/crudGenerator';

const formatIncidences = (data) => {
    if (!data) {
        return [];
    }
    const formatItem = (item) => ({
        ...item,
        label: `${item.description}`,
        value: item._id,
    });

    if (Array.isArray(data)) {
        return data.map(formatItem);
    }
    return formatItem(data);
};

export const {
    fetch: fetchIncidences,
    create: createIncidence,
    update: updateIncidence,
    delete: deleteIncidences
} = createCrudActions('INCIDENCE', "Incidencia", formatIncidences);